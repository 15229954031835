<template>
  <v-container fluid class=“vh-100”>
    <v-col v-if="retryCount < 3" cols="12" class="pl-0 pr-0 pt-0">
      <v-form class="vCenter" :style="'border-color:' + chain.primaryColor" ref="form" v-model="valid" lazy-validation>
        <v-col>
          <v-col class="text-center pt-3 pb-0">
            <img :src="chain.logo" alt="logo" style="max-height: 55px; max-width: 300px; object-fit: contain;"/>
          </v-col>
          <v-col v-if="step !== 1" class="text-center pt-0 pb-0">
            <p class="mr-1 my-auto pt-0" :style="'font-family:' + chain.font+'; font-size: 12px; color:' +
              chain.secondaryColor"><strong>{{ this.chain.company }}</strong></p>
            <v-row v-if="this.chain.chainsLogo" class="pt-3" style="align-items: center; justify-content: center;">
              <div v-for="n in this.chain.logosCadena" :key="n.cadena_nombre">
                <img :src="n.cadena_logo" alt="logo" style="max-height: 65px; max-width: 90px; object-fit: contain;"
                     class="pr-1 pl-1"/>
              </div>
            </v-row>
          </v-col>
          <p v-if="step === 1" :class="step === 1 ? 'text-center pb-0 pt-3' : 'text-center pb-0 pt-1'"
             :style="'font-size: 20px; font-family:' + chain.font+'; color: grey;'"
          ><strong>{{ titlePage }}</strong></p>
          <v-col class="pb-0 pl-0 pr-0">
            <v-stepper v-model="step" alt-labels flat>
              <v-stepper-items elevation="0">
                <v-stepper-content step="1" class="pt-0 pl-2 pr-2">
                  <!-- Formulario -->
                    <v-row class="vCenter pb-4 pt-2">
                      <div class="d-flex justify-content-center">
                        <p class="mr-1 my-auto pt-4" :class="!stepOne.type ? 'text--darken-2 font-weight-bold' :
                          'grey--text'" :style="'color:' + chain.secondaryColor">Cédula</p>
                        <v-switch
                            v-model="stepOne.type"
                            :color="chain.secondaryColor"
                            hide-details class="mt-n1 pl-2 pt-5 custom-red"
                            @change="resetDoc"
                        >
                        </v-switch>
                        <p class="ml-1 my-auto pt-4" :class="stepOne.type ? 'text--darken-2 font-weight-bold' :
                          'grey--text'" :style="'color:' + chain.secondaryColor">Pasaporte</p>
                      </div>
                    </v-row>
                    <v-text-field
                        name="miInput"
                        ref="miInput"
                        v-model="stepOne.number"
                        :label="documentLabel"
                        :rules="documentRules"
                        @change="getClient()"
                        :color="chain.secondaryColor"
                        :type="documentType"
                    ></v-text-field>
                    <v-text-field
                        v-model="stepOne.name"
                        label="Nombre *"
                        :rules="[rules.required, rules.accent]"
                        :color="chain.secondaryColor"
                        :readonly="fieldNull"
                        @click="fieldFocus()"
                    ></v-text-field>
                    <v-text-field
                        v-model="stepOne.lastname"
                        label="Apellido *"
                        :rules="[rules.required, rules.accent]"
                        :color="chain.secondaryColor"
                        :readonly="fieldNull"
                        @click="fieldFocus()"
                    ></v-text-field>
                    <v-menu
                        v-model="menu"
                        v-if="btnBirth"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        @click="fieldFocus()"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="stepOne.birth"
                            label="Fecha de Nacimiento *"
                            append-icon="mdi-calendar"
                            :color="chain.secondaryColor"
                            readonly
                            :rules="[rules.validDate]"
                            v-bind="attrs"
                            v-on="on"
                            @click="actualDate()"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="stepOne.birth" :color="chain.secondaryColor" @input="menu = false">
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                        v-model="stepOne.phone"
                        v-if="btnPhone"
                        label="Teléfono *"
                        :rules="phoneRules"
                        :color="chain.secondaryColor"
                        :type="phoneType"
                        @click="fieldFocus(); phoneTypeC()"
                        ref="phoneField"
                        :readonly="fieldNull"
                    ></v-text-field>
                    <v-text-field
                        v-model="stepOne.email"
                        label="Correo electrónico *"
                        :rules="emailRules"
                        :color="chain.secondaryColor"
                        @change="updateMail()"
                        :readonly="fieldNull"
                        @click="fieldFocus()"
                        class="pb-0"
                    ></v-text-field>
                  <!-- Fin Formulario -->

                  <!--  Nuevo Bloque de terminos y condiciones  -->

                    <div class="pr-2 pl-2 pt-0">
                      <v-sheet class="pa-1 pt-0">
                        <span v-if="termsText != ''">
                          <v-checkbox v-model="terms" label="" label-slot :color="chain.secondaryColor"
                              v-if="termsActive" :disabled="termsDisabled" class="pt-0 pb-0">
                            <template #label>
                              <div v-if="termsTextModal !== '' ? true : false" class="check pt-0 pb-0"
                                  :style="'font-family:' + chain.font">
                                <span v-html="termsText" class="text-decoration-underline" @click.stop="showModal = true;
                                  showModalFn('open', 'terms', termsTextModal)">
                                </span>
                              </div>
                              <div v-else v-html="termsText" class="switch" :style="'font-family:' + chain.font"></div>
                            </template>
                          </v-checkbox>
                        </span>

                        <span v-html="privacyText"  @click.stop="showModal = true; showModalFn('open', 'privacy', privacyTextModal)"  :style="'text-decoration: underline; font-size: 16px; cursor: pointer; font-family:' + chain.font"></span>


                      </v-sheet>
                    </div>
                  <!--  Fin Nuevo Bloque de terminos y condiciones  -->

                  <!--  Configuración de Preferencias  -->
                    <div class="pt-0 pb-0 pl-0 pr-0" :style="'font-size: 12px; text-align: justify; border: '
                      + chain.primaryColor + ' 2px solid; border-radius: 5px; overflow: auto; height: 200px;'">
                      <div v-html="explainTerms.text" class="pt-2 pb-2 pl-2 pr-2" @click="handleClick"></div>
                    </div>

                    <div id="text-container" style="overflow: auto; text-align: justify; overscroll-behavior-x: auto">
                      <v-card-text style="white-space: break-spaces;" class="pt-0 pb-0">
                      
                        <v-checkbox v-model="privacy" label-slot :color="chain.secondaryColor" class="pt-0 pb-0"
                            v-if="privacyActive" :indeterminate="privacyValidate === 'indeterminate'"
                            :disabled="privacyDisabled">
                          <template #label>Acepto el tratamiento de mis datos para los fines descritos en la Política de Protección de Datos Personales</template>
                        </v-checkbox>

                        <v-checkbox v-model="privacyConfig" label-slot :color="chain.secondaryColor" class="pt-0 pb-0"
                            v-if="privacyActive" label="Configurar preferencias">
                        </v-checkbox>
                      </v-card-text>

                      <div class="pr-2 pl-2 pt-0 pb-0" :hidden="!privacyConfig">
                        <v-sheet class="pa-1 pt-0 pb-0">
                          <v-card-text style="white-space: break-spaces;" class="pt-0 pb-0">
                            <div v-html="textModalConfig"></div>
                            <v-checkbox
                                v-model="termsMarks.experience"
                                :label="consentimientosMsg.analisis"
                                :color="chain.primaryColor"
                                :disabled="privacyDisabled"
                                @click="privacyallChecked();"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                v-model="termsMarks.communications"
                                :label="consentimientosMsg.comunicaciones"
                                :color="chain.primaryColor"
                                :disabled="privacyDisabled"
                                @click="privacyallChecked()"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                v-model="termsMarks.nationals"
                                :label="consentimientosMsg.cesionNacional"
                                :color="chain.primaryColor"
                                :disabled="privacyDisabled"
                                @click="privacyallChecked()"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                v-model="termsMarks.internationals"
                                :label="consentimientosMsg.cesionInternacional"
                                :color="chain.primaryColor"
                                :disabled="privacyDisabled"
                                @click="privacyallChecked()"
                                hide-details
                            ></v-checkbox>
                          </v-card-text>
                        </v-sheet>
                      </div>
                    </div>

                    <!-- Preguntas -->
                    <div id="text-container" style="overflow: auto; text-align: justify; overscroll-behavior-x: auto">
                      <v-card-text style="white-space: break-spaces;" class="pt-0 pb-0">
                        
                        <span v-if="preguntas.dataPreguntasProductos.length > 0">
                          <span v-if="preguntas.productos.length > 0">
                            <v-checkbox 
                              v-model="preguntasProductos" 
                              label-slot 
                              :color="chain.secondaryColor" 
                              class="pt-0 pb-0"
                              :disabled="preguntasProductoDisabled"
                              @mousedown.prevent="openModal('productos')"
                              >
                              <template #label>

                                  <span @click.stop="openModal('productos')">
                                    {{ preguntas.productos }}
                                  </span>
                              </template>
                            </v-checkbox>
                          </span>
                        </span>

                     
                        <span v-if="preguntas.canales.length > 0">
                          <span v-if="preguntas.dataPreguntasCanales.length > 0">
                            <v-checkbox 
                              v-model="preguntasCanales" 
                              label-slot 
                              :color="chain.secondaryColor" 
                              class="pt-0 pb-0"
                              :disabled="preguntasCanalDisabled"
                              @mousedown.prevent="openModal('canales')"
                            >
                              <template #label>
                                <span @click.stop="openModal('canales')">
                                  {{ preguntas.canales }}
                                </span>
                              </template>
                            </v-checkbox>
                          </span>
                        </span>

                      </v-card-text>
                    </div>
                    <!-- Fin Preguntas -->

                    <div class="pt-4 pb-0 pl-0 pr-0" style="font-size: 12px; text-align: justify;">
                      <div v-html="explainTerms.close" class="pt-2 pb-2 pl-2 pr-2"></div>
                    </div>
                  <!--  Fin Configuración de Preferencias  -->

                  <div class="text-center pt-2"></div>

                  <div :style="stepOne.policyAcept ? 'display: flex; justify-content: space-between; width: 100%;' : ''"
                       :class="!stepOne.policyAcept ? 'text-center pt-2' : ''">
                    <div :style="stepOne.policyAcept ? 'width: 60%;' : 'width: 100%;'">
                      <v-btn :color="chain.secondaryColor" class="white--text" rounded @click="validateStep1()"
                             :disabled="!enable" :style="stepOne.policyAcept ? 'flex: 1; width: 90%;' : ''">
                        {{ stepOne.policyAcept ? 'Finalizar' : 'Finalizar Encuesta' }}
                      </v-btn>
                    </div>

                    <div v-if="stepOne.policyAcept" style="width: 40%; display: flex; justify-content: flex-end;">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :color="chain.secondaryColor" class="white--text" rounded @click="goToAboutPage"
                                 :disabled="!enable" v-bind="attrs" v-on="on" style="flex: 1; width: 90%; font-size: 65%;">
                            Configurar<br/>Preferencias
                          </v-btn>
                        </template>
                        <strong>
                          <span style="color: white;">
                              Aquí puedes actualizar el alcance del uso de tus datos personales
                          </span>
                        </strong>
                      </v-tooltip>
                    </div>
                  </div>

                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-row class="vCenter">
                    <v-col cols="12">
                      <v-card cols="12" class="pb-3" :color="chain.backgroundColor" elevation="5" style="border-radius: 20px">
                        <v-card-text :class="'white--text ' + (pageEfect ? 'carta-box' : '')">
                          <div :class="pageEfect ? 'text-center carta' : 'text-center'">
                            <div :class="'white--text ' + (pageEfect ? 'cara' : '')" :hidden="rotate">
                              <img class="pb-6" src="../../images/White-check.png" alt="KFC" width="50px" />
                              <div style="font-size: 20px" v-html="qrText01"></div>
        
                              <v-col cols="12" v-if="qrActive" class="text-right pt-1">
                                <v-col v-if="modalEfect" class="pr-0">
                                  <v-btn :style="'color:' + chain.secondaryColor" rounded
                                    @click.stop="pageEfect ? faceBook() : chainsLogo = qrModal = true">
                                    Ver Código
                                  </v-btn>
                                  <v-dialog  v-model="qrModal" max-width="500px">
                                    <v-card class="pt-8">
                                      <v-card-text style="padding: 4px">
                                        <v-col style="padding: 0" class="text-center">
                                          <p v-if="!stepOne.benefit && originLanding" :style="'font-size: 20px; color: ' +
                                            chain.primaryColor">
                                            Presenta este QR en caja.
                                          </p>
                                          <qr-code v-if="!stepOne.benefit && originLanding" :value="qrData"></qr-code>
                                          <div v-if="stepOne.benefit && originLanding" style="font-size: 20px" v-html="qrText03"></div>
                                          <p v-if="!originLanding" :style="'font-size: 20px; color: ' + chain.primaryColor">
                                            {{ this.originApp }}.
                                          </p>
                                          <p v-if="stepOne.benefit" :style="'font-size: 20px; color: '+ chain.primaryColor">
                                            {{ 'Cadena: ' + this.beneficiosPorCadena.infoBeneficio.infoRestaurante.nombre_cadena }} <br>
                                            {{ 'Tienda: ' + this.beneficiosPorCadena.infoBeneficio.infoRestaurante.local }}<br>
                                            {{ 'Fecha de Canje: ' + formatFecha(this.beneficiosPorCadena.infoBeneficio.fechaCanje) }}<br>
                                            <strong>{{ 'N° Doc: ' + this.stepOne.number }}</strong><br>
                                            <strong v-if="this.stepOne.name.includes('*')">{{ 'Nombre: ' + this.stepTwo.name + ' ' + this.stepTwo.lastname }}</strong>
                                            <strong v-else>{{ 'Nombre: ' + this.stepOne.name + ' ' + this.stepOne.lastname }}</strong>
                                          </p>
                                          <p v-if="!stepOne.benefit" :style="'font-size: 20px; color: '+ chain.primaryColor">
                                            <strong>{{ 'N° Doc: ' + this.stepOne.number }}</strong><br>
                                            <strong v-if="this.stepOne.name.includes('*')">{{ 'Nombre: ' + this.stepTwo.name + ' ' + this.stepTwo.lastname }}</strong>
                                            <strong v-else>{{ 'Nombre: ' + this.stepOne.name + ' ' + this.stepOne.lastname }}</strong>
                                          </p>
                                        </v-col>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn :color="chain.secondaryColor" class="white--text"
                                               rounded @click="qrModal = false">
                                          Cerrar
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                </v-col>
                                <v-col v-else class="text-center pt-0">
                                  <qr-code :value="qrData"></qr-code>
                                </v-col>
                              </v-col>
                            </div>


                            <div :class="'white--text ' + (pageEfect ? 'cara detras' : '')" :hidden="!rotate">
                              <div style="font-size: 20px" v-html="qrText02"></div>
                              <v-col style="padding: 0" class="text-center">
                                <p v-if="!stepOne.benefit && originLanding" :style="'font-size: 20px; color: white;'">
                                  Presenta este QR en caja.
                                </p>
                                <qr-code v-if="!stepOne.benefit && originLanding" :value="qrData" :level="'H'"></qr-code>
                                <div v-if="stepOne.benefit && originLanding" style="font-size: 20px;" v-html="qrText03"></div>
                                <p v-if="!originLanding" :style="'font-size: 20px; color: white;'">
                                  {{ this.originApp }}.
                                </p>

                                <!-- Datos cliente -->
                                <p v-if="stepOne.benefit" class="white--text" style="font-size: 16px;">
                                  {{ 'Cadena: ' + this.beneficiosPorCadena.infoBeneficio.infoRestaurante.nombre_cadena }} <br>
                                  {{ 'Tienda: ' + this.beneficiosPorCadena.infoBeneficio.infoRestaurante.local }}<br>
                                  {{ 'Fecha de Canje: ' + formatFecha(this.beneficiosPorCadena.infoBeneficio.fechaCanje) }}<br>
                                  <strong>{{ 'N° Doc: ' + this.stepOne.number }}</strong><br>
                                  <strong v-if="this.stepOne.name.includes('*')">{{ 'Nombre: ' + this.stepTwo.name + ' ' + this.stepTwo.lastname }}</strong>
                                  <strong v-else>{{ 'Nombre: ' + this.stepOne.name + ' ' + this.stepOne.lastname }}</strong>
                                </p>
                                <p v-else class="white--text" style="font-size: 16px;">
                                  <strong>{{ 'N° Doc: ' + this.stepOne.number }}</strong><br>
                                  <strong v-if="this.stepOne.name.includes('*')">{{ 'Nombre: ' + this.stepTwo.name + ' ' + this.stepTwo.lastname }}</strong>
                                  <strong v-else>{{ 'Nombre: ' + this.stepOne.name + ' ' + this.stepOne.lastname }}</strong>
                                </p>
                              </v-col>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <p v-if="!chainsLogo" class="mr-1 my-auto pt-3 text-center" :style="'font-family:' + chain.font+'; ' +
                       'font-size: 16px; ' + 'color:' + chain.secondaryColor"><strong>{{ this.chain.company }}</strong></p>
                  <p v-if="!chainsLogo" style="font-size: 16px;" class="text--white pt-3 text-center">
                    <!-- <strong>{{ 'Términos y Condiciones' }}</strong><br><br>-->
                    <span v-if="this.chain.chainsLogo">{{ this.chain.name + ' y todas estas cadenas pertenecen a ' +
                      this.chain.company }}</span> <br v-if="this.chain.chainsLogo"><br v-if="this.chain.chainsLogo">
                    {{ 'Tus datos pueden ser utilizados en cualquiera de estas cadenas.' }} <br>
                  </p>
                  <div class="pt-6 pb-3" style="text-align: center;">
                    <v-btn :color="chain.secondaryColor" style="border-radius: 25px; color: white; width: 30%;" @click="backStep">
                      Volver
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3" class="pl-0 pr-0">
                  <p class="pt-3" style="text-align: center;"><strong>Revocación de Consentimiento</strong></p>
                  <p class="pb-3" style="text-align: center;">En este portal podrás revocar tu consentimiento sobre el uso de
                    tus datos por parte de {{ this.chain.company }} y sus cadenas, según lo dispuesto en la
                    Ley Orgánica de Protección de Datos Personales (LOPDP). Desmarca las casillas
                    correspondientes a los consentimientos que deseas revocar y haz clic en el botón "Revocar Consentimiento".
                  </p>
                  <v-checkbox :label="consentimientosMsg.analisis" v-model="consentimientos.analisis"
                    :color="chain.primaryColor" class="pt-0 my-1" />
                  <v-checkbox :label="consentimientosMsg.comunicaciones" v-model="consentimientos.comunicaciones"
                    :color="chain.primaryColor" class="pt-0 my-1" />
                  <v-checkbox :label="consentimientosMsg.cesionNacional" v-model="consentimientos.cesionNacional"
                    :color="chain.primaryColor" class="pt-0 my-1" />
                  <v-checkbox :label="consentimientosMsg.cesionInternacional" v-model="consentimientos.cesionInternacional"
                    :color="chain.primaryColor" class="pt-0 my-1" />
<!--                  <v-checkbox :label="consentimientosMsg.autentificacion" v-model="consentimientos.autentificacion"-->
<!--                    :color="chain.primaryColor" class="pt-0 my-1" />-->
<!--                  <v-checkbox :label="consentimientosMsg.validacionEdad" v-model="consentimientos.validacionEdad"-->
<!--                    :color="chain.primaryColor" class="pt-0 my-1" />-->
                  <div class="pt-6 pb-3" style="text-align: center;">
                    <v-btn :color="chain.secondaryColor" style="margin-right: 8px; border-radius: 25px; color: white; white-space: normal; width: 70%" @click="revocarConsentimiento">
                      {{ revocado ? "Compartir Datos Personales" : "Revocar Consentimiento" }}
                    </v-btn>
                    <v-btn :color="chain.secondaryColor" style="border-radius: 25px; color: white; width: 25%;" @click="backStep">
                      Volver
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-col>
      </v-form>
    </v-col>

    <v-col v-else :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column align-center justify-center': ''" style="height: 100vh;">
      <v-card flat class="d-flex flex-column align-center justify-center">
        <p>Ha ocurrido un error. Por favor, intenta recargando la página.</p>
        <v-btn color="primary" @click="reloadPage">Recargar página</v-btn>
      </v-card>
    </v-col>

    <!-- MODAL TERMINOS Y CONDICIONES / POLITÍCA DE PRIVACIDAD -->
      <v-col cols="12">
        <v-dialog id="modal" v-model="showModal" max-width="100%" scrollable persistent class="modal-dialog-centered">
          <v-card>
            <div id="text-container" class="overflow-y-auto" style="overflow: auto; text-align: justify;" v-scroll.self="onScroll">
              <v-card-text style="white-space: break-spaces;">
                <div v-html="textModal"></div>
              </v-card-text>
            </div>
            <v-card-actions style="border-top: 1px solid #E0E0E0;">
              <v-spacer></v-spacer>
              <v-btn :color="chain.secondaryColor" text @click="showModalFn(0, btnModal, '')">Aceptar</v-btn>
              <v-btn color="grey darken-1" text @click="showModalFn(1, btnModal, '')">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div style="position: relative; ">
          <v-dialog :width="!$vuetify.breakpoint.smAndDown ? '40%': '95%'" v-model="showAlert">
              <v-alert :color="chain.primaryColor" type="info">{{ this.msjAlert }}</v-alert>
          </v-dialog>
        </div>
      </v-col>
    <!-- FIN MODAL TERMINOS Y CONDICIONES / POLITÍCA DE PRIVACIDAD -->

    <!-- MODAL CHECK DE CONFIGURACIÓN DE POLITÍCAS DE PRIVACIDAD -->
      <v-col cols="12">
        <v-dialog id="modal" v-model="showModalConfig" max-width="100%" scrollable persistent class="modal-dialog-centered">
          <v-card>
            <div id="text-container" style="overflow: auto; text-align: justify; overscroll-behavior-x: auto">
              <v-card-text style="white-space: break-spaces;">
                <div v-html="textModalConfig"></div>
                <v-checkbox
                    v-model="termsMarks.experience"
                    label="Para personalizar mi experiencia."
                    :color="chain.primaryColor"
                    :disabled="privacyDisabled"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="termsMarks.communications"
                    label="Para mantenerte informado sobre nuestros productos y ofertas."
                    :color="chain.primaryColor"
                    :disabled="privacyDisabled"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="termsMarks.nationals"
                    label="Compartir tus datos a terceros aliados o proveedores nacionales."
                    :color="chain.primaryColor"
                    :disabled="privacyDisabled"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    v-model="termsMarks.internationals"
                    label="Compartir tus datos a terceros aliados o proveedores internacionales."
                    :color="chain.primaryColor"
                    :disabled="privacyDisabled"
                    hide-details
                ></v-checkbox>
              </v-card-text>
            </div>
            <v-card-actions style="border-top: 1px solid #E0E0E0;">
              <v-spacer></v-spacer>
              <v-btn :color="chain.secondaryColor" text @click="showModalFnConfig(0, btnModal, '')">Aceptar</v-btn>
              <v-btn color="grey darken-1" text @click="showModalFnConfig(1, btnModal, '')">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    <!-- FIN MODAL CHECK DE CONFIGURACIÓN DE POLITÍCAS DE PRIVACIDAD -->

    <!-- MODAL PREGUNTAS CANALES -->
    <v-col cols="12">
      <v-dialog id="modal" v-model="showModalPreguntasCanales" max-width="50%" scrollable persistent class="modal-dialog-centered">
        <v-card>
          <div id="text-container" class="overflow-y-auto" style="overflow: auto; text-align: justify;" v-scroll.self="onScroll">
            <v-card-text style="white-space: break-spaces;">
              <div></div>
            </v-card-text>
          </div>
          <v-card-text>
            <v-checkbox
              v-for="item in preguntas.dataPreguntasCanales"
              :key="item.id"
              :label="item.nombreMostrar"
              :value="item.id"
              v-model="selectedItemsCanales"
              :color="chain.secondaryColor"
              @change="handleCanalSelection(item)"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions style="border-top: 1px solid #E0E0E0;">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="showModalFnPreguntasCanales()">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div style="position: relative;">
        <v-dialog :width="!$vuetify.breakpoint.smAndDown ? '40%' : '95%'" v-model="showAlert">
          <v-alert :color="chain.primaryColor" type="info">{{ msjAlert }}</v-alert>
        </v-dialog>
      </div>
    </v-col>
    <!-- FIN MODAL PREGUNTAS CANALES -->
    <!-- MODAL PREGUNTAS PRODUCTOS -->
    <v-col cols="12">
      <v-dialog id="modal" v-model="showModalPreguntasProductos" max-width="50%" scrollable persistent class="modal-dialog-centered">
        <v-card>
          <div id="text-container" class="overflow-y-auto" style="overflow: auto; text-align: justify;" v-scroll.self="onScroll">
            <v-card-text style="white-space: break-spaces;">
              <div></div>
            </v-card-text>
          </div>
          <v-card-text>
            <v-checkbox
              v-for="item in preguntas.dataPreguntasProductos"
              :key="item.id"
              :label="item.nombreMostrar"
              :value="item.id"
              v-model="selectedItemsProductos"
              :color="chain.secondaryColor"
              @change="handleProductSelection(item)"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions style="border-top: 1px solid #E0E0E0;">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="showModalFnPreguntasProductos()">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div style="position: relative;">
        <v-dialog :width="!$vuetify.breakpoint.smAndDown ? '40%' : '95%'" v-model="showAlert">
          <v-alert :color="chain.primaryColor" type="info">{{ msjAlert }}</v-alert>
        </v-dialog>
      </div>
    </v-col>
    <!-- FIN MODAL PREGUNTAS PRODUCTOS -->


    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      top
      right
      color="red"
      class="snackbar-custom"
    >
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import QrCode from "@/components/qrcode/QrCode.vue";
  import CryptoJS from 'crypto-js';
  // import CaptchaView from "@/components/captcha/Captcha.vue";

  const axiosRetry = require('axios-retry');
  axiosRetry(axios, { retries: process.env.VUE_APP_API_TRY });
  export default {
    name: 'WizardRegister',
    components: {
      // CaptchaView,
      QrCode,
    },
    data() {
      return {
        revocado: false,
        snackbar: false,
        snackbarMessage: '',
        titlePage: '',
        valid: false,
        enable: false,
        step: 1,
        stepOne: {
          client_id: "",
          type: false,
          number: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
          birth: "",
          benefit: false,
          policyAcept: false,
        },
        stepTwo: {
          data: "",
          number: "",
          name: "",
          lastname: "",
          email: "",
          phone: "",
          birth: "",
        },
        terms: false,
        termsText: "",
        termsTextModal: "",
        termsActive: false,
        termsDisabled: false,
        explainTerms: {
          text: "",
          close: "",
        },
        privacy: false,
        privacyConfig: false,
        privacyComplete: false,
        privacyText: "",
        privacyTextModal: "",
        privacyActive: false,
        privacyDisabled: false,
        contact: false,
        contactText: "",
        contactTextModal: "",
        contactActive: false,
        contactDisabled: false,
        chain: {
          id: 0,
          name: "",
          prefijo: "",
          company: "",
          logo: "",
          imgBenefit: "",
          primaryColor: "",
          secondaryColor: "",
          backgroundColor: "",
          font: "",
          logosCadena: [],
          message: '',
          chainsLogo: true,
        },
        beneficiosPorCadena: [],
        data: {},
        termsMarks: {
          experience: false,
          communications: false,
          nationals: false,
          internationals: false,
        },
        menu: false,
        action: true,
        showAlert: false,
        showModal: false,
        showModalConfig: false,
        btnModal: "",
        btnPhone: true,
        btnBirth: true,
        qrActive: false,
        apply: 0,
        textModal: "",
        textModalConfig: "Consiento el tratamiento de mis datos para: <br>",
        msjAlert: "",
        rules: {
          required: (value) => !!value || "Este campo es requerido.",
          accent: (value) => /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ* ]+$/.test(value.trim()) || 'Solo permite letras acentos',
          phone: (value) => /^[\d*]+$/.test(value) || 'Solo permite números',
          numeric: (value) =>
              /^[0-9]+$/.test(value) || "Este campo debe ser numérico.",
          email: (value) =>
              /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Este campo debe ser un correo válido.",
          id10: (value) => /^\d{10}$/.test(value) || 'La cédula debe tener 10 dígitos',
          idValid: (value) => this.validateID(value) || 'La cédula no es válida',
          validDate: (value) => this.validateDate(value) || 'La fecha no puede ser igual o mayor a la fecha actual',
        },
        qrData: "QR",
        qrText01: "",
        qrText02: "",
        qrText03: "",
        retryCount: 0,
        phoneT: false,
        rotate: false,
        pageEfect: false,
        qrModal: false,
        modalEfect: false,
        benefitAcept: false,
        originLanding: true,
        originApp: "",
        uid: "",
        chainsLogo: false,
        consentimientos: {
          analisis: false,
          comunicaciones: false,
          cesionNacional: false,
          cesionInternacional: false,
          autentificacion: false,
          validacionEdad: false
        },
        consentimientosMsg: {
          analisis: '',
          comunicaciones: '',
          cesionNacional: '',
          cesionInternacional: '',
          autentificacion: '',
          validacionEdad: '',
        },
        offsetTop: 0,
        offsetTopInit: false,
        
        //Preguntas
        preguntasProductos: false,
        preguntasCanales: false,
        preguntasProductoDisabled: false,
        preguntasCanalDisabled: false,

        showModalPreguntasProductos: false,
        showModalPreguntasCanales: false,

        preguntas: {
          canales: '',
          productos: ''
        },

        dataPreguntasCanales: [],
        dataPreguntasProductos: [],

        selectedItemsProductos: null,
        selectedItemsCanales: null,
      };
    },

    methods: {
      openModal(type) {
        if (type === 'productos') {
          this.showModalPreguntasProductos = true;
          this.showModalFn('open', 'productos', '');
        }
        if (type === 'canales') {
          this.showModalPreguntasCanales = true;
          this.showModalFn('open', 'canales', '');
        }
      },
      validateStep1() {
        // Validación de campos
        if (!this.stepOne.number) {
          this.msjAlert = "Debes ingresar el número de " + (this.stepOne.type ? 'pasaporte' : 'cédula');
          this.showAlert = true;
          return false;
        }
        if ((!this.stepOne.type && this.stepOne.number.length !== 10) ||
            (!this.stepOne.type && !this.validateID(this.stepOne.number))) {
          this.msjAlert = "Debes ingresar el número de " + (this.stepOne.type ? 'pasaporte' : 'cédula') + ' válido';
          this.showAlert = true;
        }
        if (!this.stepOne.name || !this.stepOne.lastname || !this.stepOne.email) {
          this.msjAlert = "Debe ingresar todos sus datos.";
          this.showAlert = true;
          return false;
        }
        if (this.btnPhone && !this.stepOne.phone) {
          this.msjAlert = "Debe ingresar todos sus datos.";
          this.showAlert = true;
          return false;
        }
        if (this.btnBirth && !this.stepOne.birth) {
          this.msjAlert = "Debe ingresar todos sus datos.";
          this.showAlert = true;
          return false;
        }
        // Validación de uso de los switch
        if (this.termsText) {

          if (!this.terms && this.termsActive) {
            this.msjAlert = "Debe marcar la aceptación del Beneficio.";
            this.showAlert = true;
            return false;
          }

        }else{
          this.terms = true;
        }

        if ((!this.termsMarks.communications && !this.termsMarks.experience && !this.termsMarks.nationals &&
            !this.termsMarks.internationals) && this.privacyActive) {
          this.msjAlert = "Debe aceptar la política de tratamiento de datos personales.";
          this.showAlert = true;
          return false;
        }
        if (!this.contact && this.contactActive) {
          this.msjAlert = "Debe aceptar la política de Envío de Promociones y Ofertas.";
          this.showAlert = true;
          return false;
        }

        if (this.preguntas.dataPreguntasProductos.length > 0) {
          if (!this.selectedItemsProductos || this.selectedItemsProductos.length === 0) {
            this.msjAlert = "Debes seleccionar una opción en la pregunta de productos.";
            this.showAlert = true;
            return false;
          }
        }

        if (this.preguntas.dataPreguntasCanales.length > 0) {
          if (!this.selectedItemsCanales || this.selectedItemsCanales.length === 0) {
            this.msjAlert = "Debes seleccionar una opción en la pregunta de canales.";
            this.showAlert = true;
            return false;
          }
        }
        // Validación del Formulario
        if (!this.valid) { // || this.captchaValue == null
          // this.msjAlert = "Debe responder la operación de la imagen.";
          // this.showAlert = true;
          return false;
        }
        this.action ? this.saveClient() : this.updateClient();
      },
      validateID(id) {
        if (!id) { return true; }
        let province = parseInt(id.substr(0, 2));
        if (province <= 0 || province > 24) {
          return false;
        }

        let thirdDigit = parseInt(id[2]);
        if (thirdDigit >= 6) {
          return false;
        }

        let coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
        let operation = 0;
        for (let i = 0; i < coefficients.length; i++) {
          let digit = parseInt(id[i]);
          let result = digit * coefficients[i];

          if (result >= 10) {
            result -= 9;
          }
          operation += result;
        }
        let residue = operation % 10;
        let checkDigit = residue === 0 ? residue : 10 - residue;

        return checkDigit === parseInt(id[9]);
      },
      getChain() {
        if (this.retryCount >= 3) {
          console.log('Se ha alcanzado el límite de reintentos');
          return;
        }
        axios({
          method: "get",
          url: process.env.VUE_APP_API_URL + '/api/cadena/prefijo/' + this.$route.params.chain,
        })
          .then((response) => {
            // Carga de variables básicas
            localStorage.setItem('rest', "0");
            this.enable = true;
            this.chain.id = response.data.data.cdn_id;
            this.chain.logo = response.data.data.cadena_logo;
            this.chain.prefijo = response.data.data.prefijo;
            this.chain.company = response.data.data.empresa.empresa_nombre;
            this.chain.name = response.data.data.cadena_nombre;
            this.chain.imgBenefit = response.data.data.beneficio.url_imagen;
            this.chain.primaryColor = response.data.data.plantilla_pagina.colorPrimary;
            this.chain.secondaryColor = response.data.data.plantilla_pagina.colorSecondary;
            this.chain.backgroundColor = response.data.data.plantilla_pagina.colorBackground;
            this.chain.logosCadena = response.data.data.logosCadenas;
            this.chain.font = response.data.data.plantilla_pagina.nameFuente;
            this.chain.chainsLogo = response.data.data.plantilla_pagina.chainsLogo;
            this.titlePage = response.data.data.formulario.title_page;
            this.qrText01 = response.data.data.formulario.qr_message1;
            this.qrText02 = response.data.data.formulario.qr_message2;
            this.qrText03 = response.data.data.formulario.qr_message3;
            this.btnPhone = response.data.data.formulario.btn_telefono;
            this.btnBirth = response.data.data.formulario.btn_fecha_nacimiento;
            this.qrActive = response.data.data.formulario.qr_active;
            this.pageEfect = response.data.data.formulario.page_efect;
            this.modalEfect = response.data.data.formulario.qr_modal;
            localStorage.removeItem('color');
            localStorage.setItem('color', response.data.data.plantilla_pagina.colorPrimary);
            // Carga de variables de aceptación de términos
            this.termsActive = response.data.data.politicas_de_datos[0].active;
            this.termsText = response.data.data.politicas_de_datos[0].politica_texto.replace('<p>',
                '<p style="margin-bottom: 0;">');
            this.termsTextModal = response.data.data.politicas_de_datos[0].acuerdo_politica;
            this.explainTerms.text = response.data.data.empresa.politicas_de_datos[0].text;
            this.explainTerms.text +=  'Para mayor informacion ir al siguiente ';
            this.explainTerms.text +=  '<a id="clickLinkTerm">enlace</a>';

            this.explainTerms.close = response.data.data.empresa.politicas_de_datos[0].close;
            this.privacyActive = response.data.data.empresa.politicas_de_datos[0].active;
            this.privacyText = response.data.data.empresa.politicas_de_datos[0].politica_texto.replace('<p>',
                '<p style="margin-bottom: 0;">');
            this.privacyTextModal = response.data.data.empresa.politicas_de_datos[0].acuerdo_politica;
            this.contactActive = false; //response.data.data.empresa.politicas_de_datos[1].active;
            this.contactText = ''; //response.data.data.empresa.politicas_de_datos[1].politica_texto.replace('<p>',
                // '<p style="margin-bottom: 0;">');
            this.contactTextModal = '';//response.data.data.empresa.politicas_de_datos[1].acuerdo_politica;

            this.consentimientosMsg.analisis = response.data.data.consentimientosMsg.analisis;
            this.consentimientosMsg.comunicaciones = response.data.data.consentimientosMsg.comunicaciones ;
            this.consentimientosMsg.cesionNacional = response.data.data.consentimientosMsg.cesionNacional;
            this.consentimientosMsg.cesionInternacional = response.data.data.consentimientosMsg.cesionInternacional;
            this.consentimientosMsg.autentificacion = response.data.data.consentimientosMsg.autentificacion;
            this.consentimientosMsg.validacionEdad = response.data.data.consentimientosMsg.validacionEdad;

            if (!response.data.data.preguntasConfiguracion || Object.keys(response.data.data.preguntasConfiguracion).length === 0) {
                console.log("No existen configuraciones de preguntas.");
            } else {
                console.log("Existen configuraciones de preguntas.");
            }

            this.preguntas.canales = (response.data.data.preguntas && response.data.data.preguntas.canales) ? response.data.data.preguntas.canales : '';
            this.preguntas.productos = (response.data.data.preguntas && response.data.data.preguntas.productos) ? response.data.data.preguntas.productos : '';

            this.preguntas.dataPreguntasCanales = (response.data.data.preguntasConfiguracion && response.data.data.preguntasConfiguracion.canales) ? response.data.data.preguntasConfiguracion.canales : [];
            this.preguntas.dataPreguntasProductos = (response.data.data.preguntasConfiguracion && response.data.data.preguntasConfiguracion.productos) ? response.data.data.preguntasConfiguracion.productos : [];
          })
          .catch(err => {
            console.log('Error de conexión: ' + err.message);
            this.retryCount++;
            this.getChain();
          });
      },
      clearForm() {
        this.stepOne.client_id = '';
        this.stepOne.name = '';
        this.stepOne.lastname = '';
        this.stepOne.phone = '';
        this.stepOne.birth = '';
        this.stepOne.email = '';
        this.stepOne.benefit = false;
        this.terms = false;
        this.termsDisabled = false;
        this.privacy = false;
        this.privacyDisabled = false;
        this.contact = false;
        this.contactDisabled = false;
        this.termsMarks.nationals = false;
        this.termsMarks.internationals = false;
        this.termsMarks.experience = false;
        this.termsMarks.communications = false;
        this.privacyConfig = false;
        this.revocado = false;

        this.selectedItemsCanales = null;
        this.preguntasCanales = false;
        this.preguntasCanalDisabled = false;

        this.selectedItemsProductos = null;
        this.preguntasProductos = false;
        this.preguntasProductoDisabled = false;

        this.$refs.form.resetValidation();
      },
      getClient() {
        this.apply = 3;
        this.privacyConfig = false;
        if ((!this.stepOne.type && this.stepOne.number.length !== 10) ||
            (!this.stepOne.type && !this.validateID(this.stepOne.number))) {
          return false;
        }

        if (this.stepOne.number != "") {
          axios({
            method: "get",
            url: process.env.VUE_APP_API_URL + "/api/client/" + this.chain.id + "/" + this.stepOne.number,
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            }
          }).then((response) => {
            if (response.data.success) {
              this.action = false;
              this.stepOne.client_id = response.data.data.cliente._id;
              this.uid = response.data.data.cliente.uid;
              this.originLanding = response.data.data.infoAdicional.infoSistemaOrigen.isLandingPage;
              this.stepOne.policyAcept = response.data.data.infoAdicional.aceptoPoliticas;

              // Politicas de Datos
              this.consentimientos.analisis = this.termsMarks.experience =
                  response.data.data.cliente.analisisDeDatosPerfiles;
              if (response.data.data.cliente.envioComunicacionesComerciales ||
                response.data.data.cliente.envioComunicacionesComercialesPush) {
                this.consentimientos.comunicaciones = this.termsMarks.communications = true;
              }
              this.consentimientos.cesionNacional = this.termsMarks.nationals =
                  response.data.data.cliente.cesionDatosATercerosNacionales;
              this.consentimientos.cesionInternacional = this.termsMarks.internationals =
                  response.data.data.cliente.cesionDatosATercerosInternacionales;
              this.consentimientos.autentificacion = response.data.data.cliente.autenticacion;
              this.consentimientos.validacionEdad = response.data.data.cliente.mayor16anios;

              if (!this.originLanding && !this.stepOne.policyAcept) {
                this.originLanding = true;
              }
              this.originApp = response.data.data.infoAdicional.infoSistemaOrigen.message;

              if (!response.data.data.cliente.aceptacionPoliticas) {
                this.terms = false;
              } else {
                // this.apply = this.apply + 1;
                this.terms = true;
                this.termsDisabled = true;
              }
              if (!response.data.data.cliente.analisisDeDatosPerfiles || !response.data.data.cliente.cesionDatosATercerosNacionales ||
                  !response.data.data.cliente.cesionDatosATercerosInternacionales || !response.data.data.cliente.autenticacion) {
                this.privacy = false;
              } else {
                // this.apply = this.apply + 1;
                this.privacy = true;
                this.privacyDisabled = true;
              }
              if (!response.data.data.cliente.envioComunicacionesComerciales || !response.data.data.cliente.envioComunicacionesComercialesPush) {
                this.contact = false;
              } else {
                // this.apply = this.apply + 1;
                this.contact = true;
                this.contactDisabled = true;
              }
              if (!response.data.data.infoAdicional.infoBeneficio.remidioBeneficio) {
                this.stepOne.benefit = false;
                this.benefitAcept = false;
              } else {
                this.stepOne.benefit = response.data.data.infoAdicional.infoBeneficio.remidioBeneficio;
                this.benefitAcept = response.data.data.infoAdicional.infoBeneficio.remidioBeneficio;
                this.chain.message = response.data.data.infoAdicional.infoBeneficio.message;
                this.beneficiosPorCadena = response.data.data.cliente.beneficiosPorCadena[0];
              }
              if (this.apply === 3) {
                this.stepOne.type = response.data.data.cliente.tipoDocumento == "Pasaporte" ? true : false;
                if (response.data.data.cliente.primerNombre !== null && response.data.data.cliente.primerNombre !== "") {
                  this.stepOne.name = this.processInput('cadena', response.data.data.cliente.primerNombre);
                  this.stepTwo.name = response.data.data.cliente.primerNombre;
                }
                if (response.data.data.cliente.apellidos !== null && response.data.data.cliente.apellidos !== "") {
                  this.stepOne.lastname = this.processInput('cadena', response.data.data.cliente.apellidos);
                  this.stepTwo.lastname = response.data.data.cliente.apellidos;
                }
                if (response.data.data.cliente.email !== null && response.data.data.cliente.email !== "") {
                  this.stepOne.email = this.processInput('email', response.data.data.cliente.email);
                  this.stepTwo.email = response.data.data.cliente.email;
                }
                if (this.btnPhone && response.data.data.cliente.telefono !== null && response.data.data.cliente.telefono !== "") {
                  this.phoneT = true;
                  this.stepOne.phone = this.processInput('cadena', response.data.data.cliente.telefono);
                  this.stepTwo.phone = response.data.data.cliente.telefono;
                }
                if (this.btnBirth && response.data.data.cliente.fechaNacimiento !== null && response.data.data.cliente.fechaNacimiento !== "") {
                  if (response.data.data.cliente.fechaNacimiento.slice(0, 10) !== '0001-01-01') {
                    this.stepOne.birth = this.processInput('cadena', response.data.data.cliente.fechaNacimiento.slice(0, 10));
                    this.stepTwo.birth = response.data.data.cliente.fechaNacimiento.slice(0, 10);
                  }
                }
              }

              if(response.data.data.cliente.preguntaCanal) {                
                this.selectedItemsCanales = response.data.data.cliente.preguntaCanal;
                this.preguntasCanales = true;
                this.preguntasCanalDisabled = true;
              }

              if(response.data.data.cliente.preguntaProducto) {
                this.selectedItemsProductos = response.data.data.cliente.preguntaProducto;
                this.preguntasProductos = true;
                this.preguntasProductoDisabled = true;
              }
              
              if(response.data.data.revocado) {
                this.snackbarMessage = "El cliente tiene una revocatoria activa!";
                this.snackbar = true;
                this.revocado = true;
              }
              
            } else {
              this.apply = 0;
              this.action = true;
              this.clearForm();
            }
          }).catch(() => {
            this.apply = 0;
            this.action = true;
            this.clearForm();
          });
        } else {
          this.apply = 0;
          this.action = true;
          this.clearForm();
        }
      },
      saveClient() {
        const now = new Date();
        const formattedDate = now.toISOString();
        this.stepTwo.name = this.stepOne.name;
        this.stepTwo.lastname = this.stepOne.lastname;
        const data = {
          cdn_id: this.chain.id,
          restaurant_id_mxp: localStorage.rest,
          documento: this.stepOne.number,
          primerNombre: this.stepOne.name,
          apellidos: this.stepOne.lastname,
          email: this.stepOne.email,
          pais: process.env.VUE_APP_COUNTRY,
          sistemaOrigen: process.env.VUE_APP_API_APP,
          aceptacionPoliticas: this.terms,
          fechaAceptoPrivacidad: formattedDate,
          analisisDeDatosPerfiles: this.termsMarks.experience,
          cesionDatosATercerosNacionales: this.termsMarks.nationals,
          cesionDatosATercerosInternacionales: this.termsMarks.internationals,
          autenticacion: true,
          envioComunicacionesComerciales: this.termsMarks.communications,
          envioComunicacionesComercialesPush: this.termsMarks.communications,
          preguntaCanal: this.selectedItemsCanales,
          preguntaProducto: this.selectedItemsProductos,
        };
        if (this.stepOne.type) { data.tipoDocumento = "Pasaporte" } else { data.tipoDocumento = "Cedula" }
        if (this.btnPhone) { data.telefono = this.stepOne.phone }
        if (this.btnBirth) { data.fechaNacimiento = new Date(this.stepOne.birth).toISOString() }

        axios({
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/Client/",
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          data: data,
        })
            .then((response) => {
              let data = {
                'uid': response.data.uid,
                'cadena': this.chain.id,
                'cedula': response.data.documento,
                'nombre': this.stepOne.name,
                'apellido': this.stepOne.lastname,
                'email': this.stepOne.email,
              };
              this.qrData = this.encrypt(JSON.stringify(data));
              this.step = 2;
              return true;
            })
            .catch((error) => {
              console.error(error);
              return false;
            });
      },
      updateClient() {
        const now = new Date();
        const formattedDate = now.toISOString();
        const data = {
          _id: this.stepOne.client_id,
          cdn_id: this.chain.id,
          fechaActualizacion: formattedDate,
          //sistemaOrigen: process.env.VUE_APP_API_APP,
          aceptacionPoliticas: this.terms,
          fechaAceptoPrivacidad: formattedDate,
          analisisDeDatosPerfiles: this.termsMarks.experience,
          cesionDatosATercerosNacionales: this.termsMarks.nationals,
          cesionDatosATercerosInternacionales: this.termsMarks.internationals,
          autenticacion: true,
          envioComunicacionesComerciales: this.termsMarks.communications,
          envioComunicacionesComercialesPush: this.termsMarks.communications,
          preguntaCanal: this.selectedItemsCanales,
          preguntaProducto: this.selectedItemsProductos,
        };
        if (!this.stepOne.policyAcept) { data.sistemaOrigen = process.env.VUE_APP_API_APP }
        // data.cedula = this.stepOne.number.includes('*') ? this.stepOne.number : this.stepTwo.number;
        data.primerNombre = this.stepOne.name.includes('*') ? this.stepTwo.name : this.stepOne.name;
        data.apellidos = this.stepOne.lastname.includes('*') ? this.stepTwo.lastname : this.stepOne.lastname;
        data.email = this.stepOne.email.includes('*') ? this.stepTwo.email : this.stepOne.email;
        if (this.btnPhone) {
          data.telefono = this.stepOne.phone.includes('*') ? this.stepTwo.phone : this.stepOne.phone
        }
        if (this.btnBirth) {
          data.fechaNacimiento = this.stepOne.birth.includes('*') ? new Date(this.stepTwo.birth).toISOString() :
              new Date(this.stepOne.birth).toISOString()
        }

        axios({
          method: "put",
          url: process.env.VUE_APP_API_URL + "/api/client/",
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          data: data,
        })
          .then((response) => {
            if (response.data.success) {
              let data = {
                'uid': this.uid,
                'cadena': this.chain.id,
                'documento': this.stepOne.number,
                'nombre': this.stepOne.name,
                'apellido': this.stepOne.lastname,
                'email': this.stepOne.email,
              };
              this.qrData = this.encrypt(JSON.stringify(data));
              this.step = 2;
              return true;
            }
          })
          .catch((error) => {
            console.error(error);
            return false;
          });
      },
      backStep() {
        this.step = 1;
        this.resetDoc();
        this.clearForm();
      },
      encrypt(src) {
        let key = process.env.VUE_APP_SECRET_CRYPT;
        return CryptoJS.AES.encrypt(src, key).toString();
      },
      showModalFn(action, btn, html) {
        if (action == 'open') {
          this.textModal = html;
          this.btnModal = btn;
          this.offsetTopInit = false;
        } else {
          switch (btn) {
            case "terms":
              this.terms = action == 0 ? true : false;
              this.btnModal = "";
              this.textModal = html;
              break;
            case "privacy":
              this.privacy = action == 0 ? true : false;
              this.btnModal = "";
              this.textModal = html;
              break;
            case "contact":
              this.contact = action == 0 ? true : false;
              this.btnModal = "";
              this.textModal = html;
              break;
          }
          this.showModal = false;
          this.offsetTopInit = false;
        }
      },
      handleClick(event) {
      if (event.target && event.target.id === 'clickLinkTerm') {
        event.preventDefault();
        this.showModalFn('open', 'privacy', this.privacyTextModal);
        this.showModal = true; 
      }},
      showModalFnConfig(action, btn, html) {
        if (action == 'open') {
          this.textModal = html;
          this.btnModal = btn;
        } else {
          this.btnModal = "";
          this.textModal = html;
          this.showModalConfig = false;
        }
      },
      reloadPage() {
        window.location.reload();
      },
      validateDate(newDate) {
        const selectedDate = new Date(newDate);
        const now = new Date();
        if (selectedDate >= now) {
          return false;
        } else {
          return true;
        }
      },
      processInput(type, value) {
        if (type === 'cadena') {
          return value.charAt(0) + '*'.repeat(value.length - 2) + value.charAt(value.length - 1);
        } else if (type === 'email') {
          let atIndex = value.indexOf('@');
          return value.charAt(0) + '*'.repeat(atIndex - 1) + value.charAt(atIndex - 1) + '@' +
              value.charAt(atIndex + 1) + '*'.repeat(value.length - atIndex - 3) + value.charAt(value.length - 1);
        } else {
          return 'Tipo no válido';
        }
      },
      actualDate() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.stepOne.birth = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      },
      updateMail() {
        this.apply === 3 ? this.apply = 0 : '';
      },
      phoneTypeC() {
        if (this.stepOne.phone.includes('*')) {
          this.phoneT = false;
          this.stepOne.phone = this.stepTwo.phone = '';
        }
      },
      fieldFocus() {
        if (this.stepOne.number == '') {
          this.msjAlert = "Debe ingresar su número de documento.";
          this.showAlert = true;
          this.$refs.miInput.$refs.input.focus();
        }
      },
      resetDoc() {
        this.stepOne.number = '';
      },
      faceBook() {
        this.chainsLogo = true;
        let cartaBox = document.querySelector(".carta-box");
        cartaBox.classList.toggle("girar");
        this.rotate = !this.rotate;
      },
      formatFecha(fecha) {
        let date = new Date(fecha);
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric',
          minute: 'numeric' };
        return date.toLocaleDateString('es-ES', options);
      },
      goToAboutPage() {
        this.step = 3;
      },
      revocarConsentimiento() {
        const now = new Date();
        const formattedDate = now.toISOString();
        const data = {
          _id: this.stepOne.client_id,
          cdn_id: this.chain.id,
          aceptacionPoliticas: this.terms,
          fechaActualizacion: formattedDate,
          analisisDeDatosPerfiles: this.consentimientos.analisis,
          cesionDatosATercerosNacionales: this.consentimientos.cesionNacional,
          cesionDatosATercerosInternacionales: this.consentimientos.cesionInternacional,
          autenticacion: true,
          envioComunicacionesComerciales: this.consentimientos.comunicaciones,
          envioComunicacionesComercialesPush: this.consentimientos.comunicaciones,
        };
        data.primerNombre = this.stepOne.name.includes('*') ? this.stepTwo.name : this.stepOne.name;
        data.apellidos = this.stepOne.lastname.includes('*') ? this.stepTwo.lastname : this.stepOne.lastname;
        data.email = this.stepOne.email.includes('*') ? this.stepTwo.email : this.stepOne.email;

        axios({
          method: "put",
          url: process.env.VUE_APP_API_URL + "/api/client/",
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          data: data,
        })
          .then((response) => {
            console.log(response);
            this.msjAlert = (this.revocado) ? "Bienvenido, acabas de compartir nuevamente tus datos" : "Los permisos sobre el uso de tus datos han sido revocados.";
            this.showAlert = true;
            setTimeout(() => {
              this.$router.go(0);
            }, 5000);
          })
          .catch((error) => {
            console.error(error);
            return false;
          });
      },
      privacyallChecked() {
        if (this.termsMarks.communications && this.termsMarks.experience &&
            this.termsMarks.nationals && this.termsMarks.internationals) {
          this.privacy = true;
        }
        if (!this.termsMarks.communications && !this.termsMarks.experience &&
            !this.termsMarks.nationals && !this.termsMarks.internationals) {
          this.privacy = false;
        }
      },
      onScroll(e) {
        if (this.offsetTopInit) {
          this.offsetTop = e.target.scrollTop;
        } else {
          e.target.scrollTop = 0;
          this.offsetTopInit = true;
        }
      },
      showModalFnPreguntasProductos() {
        this.selectedItemsProductos = null;
        this.showModalPreguntasProductos = false;
      },
      showModalFnPreguntasCanales() {
        this.selectedItemsCanales = null;
        this.showModalPreguntasCanales = false;
      },
      handleProductSelection(item) {

        if (this.selectedItemsProductos != null) {
          this.preguntasProductos = true;
        } else {
          this.preguntasProductos = false;
        }

        this.selectedItemsProductos = item.id;
        this.showModalPreguntasProductos = false;
      },
      handleCanalSelection(item) {

        if (this.selectedItemsCanales != null) {
          this.preguntasCanales = true;
        } else {
          this.preguntasCanales = false;
        }

        this.selectedItemsCanales = item.id;
        this.showModalPreguntasCanales = false;
      },
    },

    computed: {
      documentLabel() {
        return this.stepOne.type ? "Número de Pasaporte *" : "Número de Cédula *";
      },
      documentRules() {
        return this.stepOne.type ? [this.rules.required] :
            [this.rules.id10, this.rules.required, this.rules.idValid];
      },
      documentType() {
        return this.stepOne.type ? "string" : "number";
      },
      emailRules() {
        return this.apply === 3 ? [this.rules.required] : [this.rules.required, this.rules.email];
      },
      phoneRules() {
        return this.phoneT ? [this.rules.phone, this.rules.required] : [this.rules.required, this.rules.numeric];
      },
      phoneType() {
        return this.phoneT ? "string" : "number";
      },
      fieldNull() {
        if (this.stepOne.number === null || this.stepOne.number === '') {
          return true;
        } else {
          return false
        }
      },
      allChecked() {
        return this.termsMarks.communications && this.termsMarks.experience &&
            this.termsMarks.nationals && this.termsMarks.internationals;
      },
      anyChecked() {
        return this.termsMarks.communications || this.termsMarks.experience ||
            this.termsMarks.nationals || this.termsMarks.internationals;
      },
      privacyValidate() {
        if (this.allChecked) {
          return true;
        } else if (this.anyChecked) {
          return 'indeterminate';
        } else {
          return false;
        }
      }
    },
    
    created() {
      this.getChain();
      this.documentRules;
    },

    watch: {
      // captchaValue(value) {
      //   this.valid = !!value;
      // },
      showModal(newValue) {
        if (newValue) {
          const interval = setInterval(() => {
            const textContainer = document.getElementById('text-container');
            if (textContainer) {
              textContainer.scrollTop = 0;
              clearInterval(interval);
            }
          }, 100);
        }
      },
      privacy(newVal) {
        this.termsMarks.communications = newVal;
        this.termsMarks.experience = newVal;
        this.termsMarks.nationals = newVal;
        this.termsMarks.internationals = newVal;
      }
    }
  };
</script>

<style scoped>
  @font-face {
    font-family: "National 2 Narrow Medium";
    src: url("../../assets/national-2-narrow-medium.woff2");
  }
  @font-face {
    font-family: "Helvetica Roman";
    src: url("../../assets/HelveticaNeueLTCom-Roman.ttf");
  }
  @font-face {
    font-family: "MADE Tommy Soft";
    src: url("../../assets/MADE Tommy Soft Bold.otf");
  }
  @font-face {
    font-family: "Baskerville BT";
    src: url("../../assets/BaskervilleBoldItalicBT.ttf");
  }
  @font-face {
    font-family: "Bayshore";
    src: url("../../assets/Bayshore.ttf");
  }
  @font-face {
    font-family: "Antic Didone";
    src: url("../../assets/AnticDidone-Regular.otf");
  }
  @font-face {
    font-family: "Amostely Signature";
    src: url("../../assets/AmostelySignature.ttf");
  }
  @font-face {
    font-family: "BERNIER Distressed";
    src: url("../../assets/BERNIERDistressed-Regular.otf");
  }

  .v-form {
    border: solid black;
    border-radius: 10px;
  }
  .vCenter {
    display: flex;
    justify-content: center;
  }
  .switch {
    white-space: normal;
    text-align: justify;
    font-size: 12px;
  }
  .carta-box {
    margin: 0;
    width: 100%;
    //height: 750px;
    position: relative;
    perspective: 1000px;
  }

  .carta-box.girar .carta {
    transform: rotateY(180deg);
  }

  .carta {
    position: relative;
    transform-style: preserve-3d;
    transition: all 1s linear;
  }

  .cara {
    position: relative;
    backface-visibility: hidden;
  }

  .cara.detras {
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }

  .nowrap {
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    .nowrap {
      white-space: normal;
    }
  }
</style>